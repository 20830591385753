<template>
  <div>
    <van-nav-bar
        title="我的任务"
        :border="false"
        left-arrow
        @click.native="onClickLeft"
    >
    </van-nav-bar>
    <van-pull-refresh v-model="isLoading">
      <div style="margin-bottom: 66px">
        <ArticleList :list="articleList"/>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getFaveList,myJobList} from "@/api/user";
import ArticleList from "@/components/ArticleList";
import {Toast} from "vant";
export default {
  name: "commentUp",
  components: {ArticleList},
  data() {
    return {
      submitType: this.$route.query.type,
      articleList: [],
      isLoading: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    getList(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      myJobList({
        "page": "1",
        "limit": "10",
        "sort": "1"
      }).then((res) => {
        if (res.code == 0) {
          this.articleList = res.result.records
        }
      })
    }
  }
}
</script>

<style scoped>

</style>